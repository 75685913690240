import React, { useState, useEffect, useContext } from "react";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import useDataFctory from "../useDataFactory";
import GView from "../Elements/GView";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import GConfirmDialog from "../Elements/gConfirmDialog";
import Modal from "react-modal";
import AssignRoleForm from "../forms/assignRoleForm";
import StaffForm from "../forms/staffForm";

const columns = [
  { id: "name", label: "Name", minWidth: 100, align: "left" },
  { id: "email", label: "Email", minWidth: 160, align: "center" },
  { id: "role", label: "Role", minWidth: 160, align: "center" },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 120,
    align: "center",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 120,
    align: "center",
  },
];

export default function StaffMembers() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStaffData, setSelectedStaffData] = useState({});
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [sFormVisible, setSFormVisible] = useState(false);
  const [assignRoleVisible, setAssignRoleVisible] = useState(false);

  const {
    loading,
    TableShimmer,
    Placeholder,
    data: staffData,
    refreshData,
    setData,
  } = useDataFctory("staff", false);

  const deleteStaff = () => {
    var hitUrl = "store/staff/" + selectedStaffId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = staffData.findIndex(
            (p) => p.id == selectedStaffId
          );
          if (selectedIndex > -1) {
            staffData.splice(selectedIndex, 1);
          }
          setData([...staffData]);
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <GView
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <GText g1="true" bold text={"Staff Members"} />
        <GButton
          variant="condensed"
          children="Add"
          onClick={() => {
            setSelectedStaffData({});
            setIsEmpty(true);
            setSFormVisible(true);
          }}
          style={{
            marginLeft: "auto",
          }}
        />
      </GView>

      <div className="listPaper" style={{ marginTop: "20px" }}>
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : staffData && staffData.length > 0 ? (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {staffData.map((c, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData>{c.title}</TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.email}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.role?.name || "-"}{" "}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.created || "-"}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedStaffData(c);
                            setIsEmpty(false);
                            setSFormVisible(true);
                          }}
                        >
                          <Edit style={{ width: 22, height: 22 }} />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedStaffId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete style={{ width: 22, height: 22 }} />
                        </GButton>

                        <GButton
                          variant="condensed"
                          onClick={(e) => {
                            setSelectedStaffData(c);
                            setAssignRoleVisible(true);
                          }}
                          style={{ padding: "8px 15px", borderRadius: "4px" }}
                        >
                          {c.role?.id ? "Re-Assign" : "Assign"} Role
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Placeholder />
        )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Staff Member"
          text="Are you sure you want to delete this Staff member?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteStaff();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={sFormVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSFormVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Staff Member Form Modal"
      >
        <StaffForm
          sFormVisible={sFormVisible}
          setSFormVisible={setSFormVisible}
          isEmpty={isEmpty}
          data={selectedStaffData}
          onSubmit={(data) => {
            let selectedIndex = staffData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              staffData[selectedIndex] = { ...data };
            } else {
              staffData.push(data);
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={assignRoleVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAssignRoleVisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Assign Role Form Modal"
      >
        <AssignRoleForm
          assignRoleVisible={assignRoleVisible}
          setAssignRoleVisible={setAssignRoleVisible}
          staffData={selectedStaffData}
          onSubmit={() => refreshData()}
        />
      </Modal>
    </div>
  );
}
