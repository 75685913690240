import React, { useState, useEffect } from "react";
import "../assets/css/invoice.css";
import {
  ApiService,
  ImgUrl,
  addDefaultLogo,
  getProductTitle,
} from "../services";
import { useParams } from "react-router-dom";

import useSettings from "../useSettings";

function OrderInvoice(props) {
  const setting = useSettings();
  let { bookingId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + bookingId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setInvoiceData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("uhAccessToken")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="invoiceDiv">
      <div
        className="flex"
        style={{
          width: "40%",
        }}
      >
        <img
          src={ImgUrl("logo") + "/" + setting.email_logo}
          alt="logo"
          onError={addDefaultLogo}
        />
      </div>
      <div
        className="flex"
        style={{
          width: "60%",
        }}
      >
        <div>
          <br />
          <p>{setting?.invoice_header_text}</p>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>Booking Date</label>
            <p>{invoiceData.created ? invoiceData.created_full : ""}</p>

            <br />
            <label>Invoice Number</label>
            <p>{"#" + bookingId}</p>
          </div>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>Invoice</label>
            <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
            <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
            <p>
              {invoiceData.delivery
                ? invoiceData.delivery.delivery_address
                : ""}
            </p>

            <br />

            {invoiceData.product_id && (
              <>
                <label>{getProductTitle()} Info</label>
                <p>{invoiceData.product_title || ""}</p>
                <p>{invoiceData.product?.address || ""}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <br />

      <label>
        Thank you for using the services of {setting?.project_name}.
      </label>
      <p>Powered by Unify</p>
      <br />
      <p>{setting?.invoice_footer_text}</p>
    </div>
  );
}

export default OrderInvoice;
