import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import useDataFctory from "../useDataFactory";

export default function AssignRoleForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    loading,
    Shimmer,
    Placeholder,
    data: rolesData,
    refreshData,
  } = useDataFctory("roles", false);

  const initialValues = {
    host_id: props.staffData.id,
    role_id: props.staffData?.role?.id || "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    ApiService({ method: "PUT", route: "role/assign", body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code == 1) {
          props.setAssignRoleVisible(false);
          props.onSubmit(response.data);
        }
        setIsDisabled(false);
        enqueueSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    role_id: Yup.string().required("Required"),
  });

  if (props.assignRoleVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">Assign Role</div>
      <div className="modal-body">
        {loading == false ? (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {rolesData.length > 0 ? (
                  <>
                    <FormikControl
                      control="radio"
                      label="Choose a Role"
                      name="role_id"
                      options={rolesData}
                      key_title="name"
                      key_value="id"
                      custom={true}
                      divstyle={{ display: "block", margin: "3px 0" }}
                    />
                    <br />
                    <GButton
                      variant="condensed"
                      disabled={isDisabled}
                      children={"Assign"}
                      type="submit"
                      style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
                    />
                  </>
                ) : (
                  <GText
                    med
                    text={"No roles have been added yet. "}
                    style={{ padding: "10px 15px" }}
                  />
                )}
              </Form>
            )}
          </Formik>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
