import React, { createContext, useEffect, useState, useContext } from "react";
import { ApiService, ApiLoginService } from "../services";
import useLocalStorage from "./useLocalStorage";

const SettingsContext = createContext(null);

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useLocalStorage("settings"); // useState({});

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      return;
    }
    refreshSettings();
  }, []);

  const refreshSettings = () => {
    var token = localStorage.getItem("uhAccessToken");
    var rtoken = localStorage.getItem("uhRefreshToken");
    var expires = localStorage.getItem("uhExpiresIn");
    if (token && rtoken && expires && expires > 0) {
      ApiService({ method: "GET", route: "me" }).then((response) => {
        console.log(response.data);
        if (response.data) {
          setSettings(response.data.data);
        }
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider
      value={{ settings, setSettings, refreshSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };

export default () => useContext(SettingsContext);
