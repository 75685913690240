import react from "react";
import Echo from "laravel-echo";

export function createSocketConnection() {
  window.Pusher = require("pusher-js");

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "anyKey",
    wsHost: process.env.REACT_APP_WS_HOST,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    scheme: "https",
    forceTLS: true,
    auth: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("uhAccessToken"),
      },
    },
  });
}
