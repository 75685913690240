import dashboardIcon from "../assets/images/sidebar/dashboard.svg";
import orderIcon from "../assets/images/sidebar/orders.svg";
import productIcon from "../assets/images/sidebar/products.svg";
import reviewIcon from "../assets/images/sidebar/reviews.svg";
import { getProductTitle } from "../services";

export const menus = [
  {
    icon: dashboardIcon,
    title: "Dashboard",
    path: "/dashboard",
    state: "dashboard",
    isLink: true,
    isStaff: "dashboard_index",
  },
  {
    icon: orderIcon,
    title: "Bookings",
    path: "/bookings",
    state: "booking",
    isLink: true,
    isStaff: "order_index",
  },
  {
    icon: productIcon,
    title: getProductTitle(),
    path: "/products",
    state: "product",
    isLink: true,
    isStaff: "product_index",
  },
  {
    icon: reviewIcon,
    title: "Reviews",
    path: "/reviews",
    state: "review",
    isLink: true,
    isStaff: "review_index",
  },
];
