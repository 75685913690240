import React from "react";
import Highcharts from "highcharts";
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Title,
  Subtitle,
  AreaSplineSeries,
  LineSeries,
  Legend,
} from "react-jsx-highcharts";
import useSettings from "../useSettings";

const plotOptions = {
  series: {
    animation: {
      duration: 5000,
    },
  },
};
const labels = {
  style: {
    fontSize: "20px",
  },
};
const marker = {
  symbol: "circle",
};

function GCharts(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const settings = localStorage.getItem("settings")
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      JSON.parse(localStorage.getItem("settings"))
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useSettings();

  var tooltip = {
    valueSuffix: "\xB0C",
    formatter: function () {
      return this.points.reduce(function (s, point) {
        if (point.series.name === "Transaction") {
          return (
            s +
            "<br/>" +
            point.series.name +
            ": <b> " +
            settings.currency_symbol +
            point.y.toLocaleString("en-US") +
            "</b>"
          );
        } else {
          return (
            s +
            "<br/>" +
            point.series.name +
            ": <b>" +
            point.y.toLocaleString("en-US") +
            "</b>"
          );
        }
      }, "<b>" + this.x + "</b>");
    },
    shared: true,
  };

  return (
    <HighchartsChart
      plotOptions={plotOptions}
      tooltip={tooltip}
      marginLeft={300}
    >
      <Chart height={props.height} />
      <Title align="center"></Title>
      <Subtitle></Subtitle>
      {props.legend === true && (
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
      )}

      <XAxis categories={props.labels} lable={labels}>
        <XAxis.Title></XAxis.Title>
      </XAxis>

      <YAxis>
        <YAxis.Title></YAxis.Title>
        {props.type === "areaspline" && (
          <AreaSplineSeries
            name={props.name}
            color={props.color}
            data={props.values}
            marker={marker}
          />
        )}
        {props.type === "line" && (
          <LineSeries
            name={props.name}
            color={props.color}
            data={props.values}
            marker={marker}
          />
        )}

        {props.values2 && (
          <LineSeries
            name={props.name2}
            color={props.color2}
            data={props.values2}
            marker={marker}
          />
        )}
        {props.values3 && (
          <LineSeries
            name={props.name3}
            color={props.color3}
            data={props.values3}
            marker={marker}
          />
        )}
      </YAxis>
    </HighchartsChart>
  );
}

export default withHighcharts(GCharts, Highcharts);
