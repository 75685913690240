import React, { useState, useEffect } from "react";
import "../assets/css/invoice.css";
import {
  ApiService,
  ImgUrl,
  addDefaultLogo,
  getProductTitle,
} from "../services";
import useSettings from "../useSettings";
import { useParams } from "react-router-dom";

export default function OrderThermalInvoice() {
  const setting = useSettings();
  let { bookingId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});

  useEffect(() => {
    const getOrderDetail = () => {
      ApiService({ method: "GET", route: "orders/" + bookingId })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setInvoiceData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    console.log(localStorage.getItem("uhAccessToken"));
    console.log(setting);
    if (localStorage.getItem("uhAccessToken")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="thermalInvoiceDiv">
      <img
        src={ImgUrl("logo") + "/" + setting?.email_logo}
        alt="logo"
        onError={addDefaultLogo}
      />
      <hr />

      <div>
        <p>{setting?.invoice_header_text}</p>
        <h4>Booking no.</h4>
        <p>{"#" + bookingId}</p>
        <p>{invoiceData.created ? invoiceData.created_full : ""}</p>
        <br />
        <h4>Customer</h4>
        <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
        <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
        <p>
          {invoiceData.delivery ? invoiceData.delivery.delivery_address : ""}
        </p>
        <hr />
        <h4>Booking</h4>
        <p>Starts - {invoiceData.starts ? invoiceData.starts_display : ""}</p>
        <p>Ends - {invoiceData.ends ? invoiceData.ends_display : ""}</p>

        {invoiceData?.product_id && (
          <>
            <hr />

            <h4>{getProductTitle()}</h4>
            <p> {invoiceData.product_title || ""}</p>
            <p> {invoiceData.product?.address || ""}</p>
          </>
        )}
      </div>

      <br />

      <p>Thank you for using the services of {setting?.project_name}.</p>
      <p>Powered by Unify</p>
      <br />
      <p>{setting?.invoice_footer_text}</p>
    </div>
  );
}
