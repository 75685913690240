import React, { useState, useEffect, useRef } from "react";
import { TableContainer } from "@mui/material";
import { useSnackbar } from "notistack";
import { ImgUrl, ApiService, getProductTitle } from "../services";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ReactComponent as ProductIcon } from "../assets/images/sidebar/products.svg";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../Elements/gEmpty";
import GTableShimmer from "../Elements/gTableShimmer";
import GButton from "../Elements/GButton";
import GConfirmDialog from "../Elements/gConfirmDialog";
import GPagination from "../Elements/gPagination";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ProductCreatePopup from "../forms/productCreateForm";
import ProductEditPopup from "../forms/productEditForm";
import QueryWith from "../Query";
import GSelectbox from "../Elements/gSelectbox";
import { GSearch } from "../Elements/gSearch";
import GSwitch from "../Elements/gSwitch";
import useSettings from "../useSettings";
import Modal from "react-modal";

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

export default function Products(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [productsPaginationData, setProductsPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");
  const [pcvisible, setPcvisible] = useState(false);
  const [pevisible, setPevisible] = useState(false);
  const settings = useSettings(true);
  const [productFormKeys, setProductFormKeys] = useState([]);
  const [productEditFormKeys, setProductEditFormKeys] = useState([]);
  let navigate = useNavigate();
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    status: params.get("status") ? params.get("status") : "",
  });

  const columns = [
    { id: "title", label: "Title", minWidth: 130 },
    { id: "photo", label: "Photo", minWidth: 50, type: "image" },
    { id: "status", label: "Status", minWidth: 60 },
    { id: "actions", label: "Actions", minWidth: 60, align: "center" },
  ];

  useEffect(() => {
    ApiService({ method: "OPTIONS", route: "products" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setProductFormKeys(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getProducts = () => {
    setIsLoading(true);
    var params = QueryWith(location, filters);
    if (params.length > 0) {
      navigate(
        {
          pathname: "/products",
          search: params[0],
        },
        { state: "product" }
      );
    }
    ApiService({ method: "GET", route: "products", body: filters })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setProductsData(response.data.data.data);
          setProductsPaginationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProducts();
  }, [filters]);

  function addDefaultLogo(e) {
    e.target.src = itemPlaceholder;
  }

  const deleteProduct = () => {
    ApiService({ method: "DELETE", route: "products/" + selectedProductId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getProducts();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const handleToggleStatus = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route: "products/" + id,
      body: {
        status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          productsData[ind].status = newVal;
          setProductsData([...productsData]);
        } else {
          getProducts();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoading == false) {
      setPevisible(true);
    }
  }, [productEditFormKeys]);

  return (
    <div className="mainInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setPcvisible(true);
        }}
        style={{
          float: "right",
          padding: "0.6rem 2rem",
          height: "max-content",
        }}
      />
      <h1>
        <ProductIcon style={{ fill: "#000" }} /> &nbsp;{getProductTitle()}
      </h1>

      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "7px",
          }}
        >
          <GSearch
            placeholder="Search by title.."
            value={filters.search}
            handleChange={(val) => {
              setFilters({ ...filters, search: val, page: 1 });
            }}
          />

          <GSelectbox
            placeholder="All"
            defaultvalue={filters.status}
            data={statusFilters}
            id="item_status_filter"
            option_key="title"
            option_value="value"
            onChange={(newVal) => {
              setFilters({ ...filters, status: newVal, page: 1 });
            }}
            style={{
              border: "1px solid #e2e2e2",
              height: "40px",
              minWidth: "150px",
            }}
          />

          {productsData.length > 0 && (
            <GPagination
              style={{ display: "inline-block", marginLeft: "auto" }}
              count={productsPaginationData.total}
              from={productsPaginationData.from}
              to={productsPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(productsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(productsPaginationData.prev_page_url)
              }
              rowsPerPage={productsPaginationData.per_page}
              prevDisabled={productsPaginationData.prev_page_url ? false : true}
              nextDisabled={productsPaginationData.next_page_url ? false : true}
              listStyle={{ margin: "10px 0 0" }}
            ></GPagination>
          )}
        </div>

        <div className="listPaper" style={{ marginTop: "15px" }}>
          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            <TableContainer>
              {productsData.length === 0 ? (
                <GEmpty></GEmpty>
              ) : (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, ind) => {
                        return (
                          <TableHeader
                            key={column.id}
                            style={{
                              minWidth: column.minWidth,
                              textAlign: column.align,
                            }}
                          >
                            {column.label}
                          </TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productsData.map((c, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={c.id}
                        >
                          <TableRowData
                            style={{
                              BorderBottom: "1px solid #f4f5f9",
                            }}
                          >
                            {c.title || c.custom_fields?.title}
                          </TableRowData>
                          <TableRowData>
                            <img
                              alt={c.custom_fields?.title}
                              src={
                                c.images && c.images.length > 0
                                  ? ImgUrl("item") +
                                    "/" +
                                    c.images[0].thumb_photo
                                  : ""
                              }
                              onError={addDefaultLogo}
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "50%",
                              }}
                            />
                          </TableRowData>
                          <TableRowData>
                            <GSwitch
                              id={"item" + c.id}
                              uId={c.id}
                              currentValue={
                                c.status === "true" ||
                                c.status === "1" ||
                                c.status === 1
                                  ? 1
                                  : 0
                              }
                              disabled={false}
                              onSwitchChange={(newVal, id) =>
                                handleToggleStatus(newVal, id, i)
                              }
                            />
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedProductData(c);
                                let editFormKeys = [...productFormKeys];
                                editFormKeys.map((f) => {
                                  f.value = c.custom_fields[f.id];
                                });
                                setProductEditFormKeys([...editFormKeys]);
                                console.log(editFormKeys);

                                // setPevisible(true);
                              }}
                            >
                              <Edit
                                fontSize="small"
                                style={{ width: "18px" }}
                              />
                            </GButton>
                            <GButton
                              variant="linkable"
                              onClick={(e) => {
                                setSelectedProductId(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <Delete
                                fontSize="small"
                                style={{ width: "18px" }}
                              />
                            </GButton>
                          </TableRowData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}
        </div>
      </React.Fragment>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={"Delete " + getProductTitle()}
          text={
            "Are you sure you want to delete this " + getProductTitle() + "?"
          }
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProduct();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={pcvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPcvisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Product Create Form Modal"
      >
        <ProductCreatePopup
          pcvisible={pcvisible}
          setPcvisible={setPcvisible}
          hostId={filters.host_id}
          onSubmit={getProducts}
          settings={settings}
          fields={productFormKeys}
        />
      </Modal>

      <Modal
        isOpen={pevisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPevisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Product Edit Form Modal"
      >
        <ProductEditPopup
          pevisible={pevisible}
          setPevisible={setPevisible}
          hostId={filters.host_id}
          data={selectedProductData}
          settings={settings}
          onSubmit={getProducts}
          fields={productEditFormKeys}
        />
      </Modal>
    </div>
  );
}
