import React, { useState, useEffect } from "react";
import { Grid, TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import "../assets/css/dashboard.css";
import { ApiService, isPermissionExist, isHost } from "../services";
import { Card } from "../styledComponents/cardStyles";
import GDashboardCounts from "../Elements/gDashboardCounts";
import GCharts from "../Elements/gCharts";
import GEmpty from "../Elements/gEmpty";
import GText from "../Elements/GText";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";

const columns = [
  { id: "id", label: "Id" },
  { id: "status", label: "Status", minWidth: 40 },
  { id: "customer", label: "Customer", minWidth: 80 },
  { id: "created_at_formatted", label: "Created", minWidth: 110 },
];

const paperStyle = {
  height: "400px",
  overflow: "auto",
  border: "1px solid #e8e8e8",
};

function DashboardData() {
  const [dashboardData, setDashboardData] = useState({
    recent_orders: [],
    daily_orders_graph: [],
    monthly_orders_graph: [],
    age_gender_graph: [],
    busier_days_graph: [],
    busier_hours_graph: [],
  });
  const settings = useSettings(true);

  useEffect(() => {
    ApiService({ method: "GET", route: "dashboard" })
      .then((response) => {
        console.log(response.data);
        setDashboardData(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div id="dashboard" className="mainInnerView">
      {dashboardData ? (
        <>
          <h1>Dashboard</h1>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              {dashboardData.orders ? (
                <GDashboardCounts
                  title="Total Bookings"
                  subtitle="Total active bookings"
                  total_count={dashboardData.orders}
                  type="order"
                />
              ) : (
                ""
              )}
            </Grid>
            {isHost() || isPermissionExist("order_index") ? (
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Card style={paperStyle}>
                    <GText
                      g3
                      bold
                      text={"Daily Bookings"}
                      style={{ color: "#333", paddingLeft: "8px" }}
                    />
                    <GText
                      med
                      text={"Total Bookings for this month"}
                      style={{ padding: "5px 0 20px 8px" }}
                    />
                    <GCharts
                      labels={
                        dashboardData?.daily_orders_graph &&
                        dashboardData?.daily_orders_graph.map((l) => {
                          return l.label;
                        })
                      }
                      values={
                        dashboardData?.daily_orders_graph &&
                        dashboardData?.daily_orders_graph.map((l) => {
                          return l.count;
                        })
                      }
                      height="320"
                      color="#46ca7c"
                      type="areaspline"
                      name="Bookings"
                    />
                  </Card>
                </Grid>{" "}
                <Grid item lg={6} xs={12}>
                  <Card style={paperStyle}>
                    <GText
                      g3
                      bold
                      text={"Monthly Bookings"}
                      style={{ color: "#333", paddingLeft: "8px" }}
                    />
                    <GText
                      med
                      text={"Total Bookings for this year"}
                      style={{ padding: "5px 0 20px 8px" }}
                    />
                    <GCharts
                      labels={
                        dashboardData?.monthly_orders_graph &&
                        dashboardData?.monthly_orders_graph.map((l) => {
                          return l.label;
                        })
                      }
                      values={
                        dashboardData?.monthly_orders_graph &&
                        dashboardData?.monthly_orders_graph.map((l) => {
                          return l.count;
                        })
                      }
                      height="320"
                      color="#46ca7c"
                      type="areaspline"
                      name="Bookings"
                    />
                  </Card>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Card style={paperStyle}>
                    <GText
                      g3
                      bold
                      text={"Age and Gender "}
                      style={{ color: "#333", paddingLeft: "8px" }}
                    />
                    <GText
                      med
                      text={""}
                      style={{ padding: "5px 0 20px 8px" }}
                    />
                    <GCharts
                      labels={
                        dashboardData?.age_gender_graph &&
                        dashboardData?.age_gender_graph.map((l) => {
                          return l.label;
                        })
                      }
                      values={
                        dashboardData?.age_gender_graph &&
                        dashboardData?.age_gender_graph.map((l) => {
                          return l.male;
                        })
                      }
                      values2={
                        dashboardData?.age_gender_graph &&
                        dashboardData?.age_gender_graph.map((l) => {
                          return l.female;
                        })
                      }
                      values3={
                        dashboardData?.age_gender_graph &&
                        dashboardData?.age_gender_graph.map((l) => {
                          return l.other;
                        })
                      }
                      height="320"
                      color="#46ca7c"
                      type="line"
                      name="Male"
                      name2="Female"
                      color2="#3994FF"
                      name3="Other"
                      color3="#e56d87"
                      legend={true}
                    />
                  </Card>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Card style={paperStyle}>
                    <GText
                      g3
                      bold
                      text={"Busier Days"}
                      style={{ color: "#333", paddingLeft: "8px" }}
                    />
                    <GText
                      med
                      text={""}
                      style={{ padding: "5px 0 20px 8px" }}
                    />
                    <GCharts
                      labels={
                        dashboardData?.busier_days_graph &&
                        dashboardData?.busier_days_graph.map((l) => {
                          return l.label;
                        })
                      }
                      values={
                        dashboardData?.busier_days_graph &&
                        dashboardData?.busier_days_graph.map((l) => {
                          return l.count;
                        })
                      }
                      height="290"
                      color="#e56d87"
                      type="line"
                      name="Bookings"
                    />
                  </Card>
                </Grid>{" "}
                <Grid item lg={6} xs={12}>
                  <Card style={paperStyle}>
                    <GText
                      g3
                      bold
                      text={"Busier Hours"}
                      style={{ color: "#333", paddingLeft: "8px" }}
                    />

                    <GText
                      med
                      text={""}
                      style={{ padding: "5px 0 20px 8px" }}
                    />
                    <GCharts
                      labels={
                        dashboardData?.busier_hours_graph &&
                        dashboardData?.busier_hours_graph.map((l) => {
                          return l.label;
                        })
                      }
                      values={
                        dashboardData?.busier_hours_graph &&
                        dashboardData?.busier_hours_graph.map((l) => {
                          return l.count;
                        })
                      }
                      height="290"
                      color="#e56d87"
                      type="line"
                      name="Bookings"
                    />
                  </Card>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Card style={paperStyle}>
                    <GText
                      g3
                      bold
                      text={"Recent Bookings"}
                      style={{ color: "#333", padding: "8px" }}
                    />
                    <TableContainer>
                      {dashboardData?.recent_orders &&
                      dashboardData?.recent_orders?.length > 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableHeader
                                  key={column.id}
                                  style={{
                                    minWidth: column.minWidth,
                                    textAlign: column.align,
                                  }}
                                >
                                  {column.label}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {dashboardData?.recent_orders.map((o) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={o.id}
                                  className="bordered"
                                >
                                  <TableRowData
                                    style={{
                                      BorderBottom: "1px solid #f4f5f9",
                                    }}
                                  >
                                    <Link
                                      to={{
                                        pathname: "/booking/" + o.id,
                                        state: "booking",
                                      }}
                                    >
                                      #{o.id}
                                    </Link>
                                  </TableRowData>
                                  <TableRowData>
                                    <span
                                      className="orderStatus"
                                      style={{
                                        color: o.status_display?.color1
                                          ? "#" + o.status_display?.color1
                                          : "#333",
                                        background: o.status_display?.color2
                                          ? "#" + o.status_display?.color2
                                          : "transparent",
                                        padding: "5px 11px ",
                                        fontSize: "0.9em",
                                        fontWeight: "550",
                                      }}
                                    >
                                      {o.status_display?.title}
                                    </span>
                                  </TableRowData>
                                  <TableRowData>
                                    {o.customer.title}
                                  </TableRowData>
                                  <TableRowData className="created">
                                    {o.created}
                                  </TableRowData>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : (
                        <GEmpty />
                      )}
                    </TableContainer>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <GText
          light
          bold
          g1
          text={"Welcome to dashboard"}
          style={{
            display: "flex",
            height: "calc(100vh - 150px)",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "42px",
            textTransform: "uppercase",
          }}
        />
      )}
    </div>
  );
}

export default DashboardData;
