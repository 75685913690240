import React, { useState, useEffect, useRef } from "react";
import { ApiService, getProductTitle } from "../services";
import CustomSeparator from "../Elements/gBreadCrumbs";
import GInfo from "../Elements/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GTableContent } from "../Elements/gContent";
import { ReactComponent as Send } from "../assets/images/icons/send.svg";
import { NoteDiv } from "../styledComponents/notesStyles";
import { MessageBox } from "../styledComponents/messageStyles";
import CancelReasonsView from "../Components/orderCancelReasons";
import CancelBox from "../Elements/gCancelBox";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import GDropdown from "../Elements/gDropdown";
import useSettings from "../useSettings";
import GButton from "../Elements/GButton";
import { useSnackbar } from "notistack";
import { GContent } from "../Elements/gContent";
import { useParams, useNavigate } from "react-router-dom";
import GText from "../Elements/GText";
import Modal from "react-modal";
import { createSocketConnection } from "../useLaravelPusher";

const fixedDivStyle = {
  display: "flex",
  bottom: "5px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "8",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function BookingDetail(props) {
  let { bookingId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [ocrvisible, setOcrvisible] = useState(false);
  const [noteVal, setNoteValue] = useState("");
  const [messageVal, setMessageValue] = useState("");
  let navigate = useNavigate();
  const [orderData, setOrderData] = useState({
    customer: {},
    payment_summary: {
      data: [],
    },
    cancelled_log: {},
  });
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [ordernotes, setOrderNotes] = useState([]);
  const [orderMessages, setOrderMessages] = useState([]);
  const [msgBtnDisabled, setMsgBtnDisabled] = useState(false);
  const [noteBtnDisabled, setNoteBtnDisabled] = useState(false);
  const settings = useSettings(true);
  const ViewDropdownRef = useRef(null);
  const [isViewDropdownActive, setIsViewDropdownActive] = useDetectOutsideClick(
    ViewDropdownRef,
    false
  );

  const invoiceList = [
    {
      title: "Thermal Invoice",
      link: "/booking-thermal-invoice/" + bookingId,
    },
    {
      title: "A4 Invoice",
      link: "/booking-invoice/" + bookingId,
    },
  ];

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + bookingId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatuses(response.data?.options);
          setOrderStatus(response.data.data.status_display.title);
          setOrderNotes(response.data.data.order_notes);
          setOrderMessages(response.data.data.order_messages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("order-updated." + bookingId).listen(
      "BCOrderUpdated",
      function (e) {
        console.log(e);
        getOrderDetail();
        return () => {
          window.Echo.leaveChannel(`order-updated.${bookingId}`);
        };
      }
    );

    navigate(
      {
        pathname: "/booking/" + bookingId,
      },
      {
        state: "booking",
      }
    );

    getOrderDetail();
  }, [bookingId]);

  useEffect(() => {
    if (orderStatuses && orderStatuses.length > 0 && orderStatus) {
      var keepGoing = true;
      const listCopy = [...orderStatuses];
      //Logic to update your list here

      listCopy.forEach((user, index) => {
        if (keepGoing === true) {
          user["color"] = "colored";
          if (user.title === orderStatus) {
            keepGoing = false;
          }
        } else {
          user["color"] = "";
        }
      });
      setOrderStatusList(listCopy);
    }
  }, [orderStatuses, orderStatus]);

  function updateField(selectedFieldId, newValue) {
    if (selectedFieldId === "notes") {
      setNoteBtnDisabled(true);
    }
    if (selectedFieldId === "messages") {
      setMsgBtnDisabled(true);
    }
    var post_note = {
      order_id: bookingId,
      user_type: "2",
      user_id: settings?.user?.id,
      text:
        selectedFieldId === "notes"
          ? noteVal
          : selectedFieldId === "messages"
          ? messageVal
          : newValue,
    };
    ApiService({
      method: "POST",
      route: "order/" + selectedFieldId,
      body: post_note,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (selectedFieldId === "notes") {
            setNoteValue("");
            setOrderNotes([...ordernotes, response.data.data]);
          } else {
            setMessageValue("");
            setOrderMessages([...orderMessages, response.data.data]);
          }
        }
        enqueueSnackbar(response.data.message);
        setNoteBtnDisabled(false);
        setMsgBtnDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCompleteOrder() {
    ApiService({ method: "POST", route: "order/complete/f/" + bookingId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatus(response.data.data?.status_display?.title);
          //getOrderDetail();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateOrderStatus(status) {
    ApiService({ method: "POST", route: "order/" + status + "/" + bookingId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatus(response.data.data?.status_display?.title);
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 30px" }}>
      <h1 style={{ display: "inline-block" }}>
        #{bookingId} &nbsp;&nbsp;
        {orderStatus !== "Cancelled" && (
          <small
            onClick={() => setIsViewDropdownActive(true)}
            style={{
              color: "#F8310E",
              textDecoration: "underline",
              fontStyle: "italic",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            View Invoice
            {isViewDropdownActive === true ? (
              <div ref={ViewDropdownRef}>
                <GDropdown
                  id="viewInvoice"
                  className={`menu ${
                    isViewDropdownActive ? "active" : "inactive"
                  }`}
                  listData={invoiceList}
                  onSelectValue={() => {
                    setIsViewDropdownActive(false);
                  }}
                />
              </div>
            ) : (
              ""
            )}{" "}
          </small>
        )}
      </h1>

      {orderData?.status &&
      (orderData?.status === "pending" ||
        orderData?.status === "processing" ||
        orderData?.status === "accepted" ||
        orderData?.status === "started" ||
        orderData?.status === "ready") ? (
        <CancelBox
          handleClick={() => setOcrvisible(true)}
          handleCompleteForcefully={() => handleCompleteOrder()}
        />
      ) : orderData?.status === "cancelled" ? (
        <CancelBox data={orderData.cancelled_log} />
      ) : (
        ""
      )}

      <CustomSeparator
        statusList={orderStatusList}
        class={orderStatus}
        updateStatus={true}
        onUpdateStatus={(status) => updateOrderStatus(status)}
      />

      <div
        style={{
          width: "67%",
          display: "inline-block",
          paddingRight: "15px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "47%",
            display: "inline-block",
            boxSizing: "border-box",
            verticalAlign: "top",
            marginRight: "20px",
          }}
        >
          <Card style={{ padding: "20px 15px 25px" }}>
            <GText
              g4
              semi
              text={"Customer Details"}
              style={{ marginBottom: "10px" }}
            />
            <GInfo
              title={orderData.customer?.title}
              key={orderData.customer?.id}
              subtitle={orderData.customer?.phone}
              photo={orderData.customer?.thumb_photo}
              imgType="user"
            />
          </Card>
          <Card>
            <GText
              g4
              semi
              text={getProductTitle() + " Info"}
              style={{ marginBottom: "10px" }}
            />
            {orderData?.product_id && (
              <GInfo
                title={orderData.product_title}
                subtitle={orderData.product?.address}
                photo={
                  orderData.product?.images.length > 0
                    ? orderData.product?.images[0].thumb_photo
                    : ""
                }
                imgType="item"
              />
            )}
            <br />
            <br />
          </Card>
        </div>
        <div
          style={{
            width: "calc(53% - 20px)",
            display: "inline-block",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          {orderData?.order_units && orderData?.order_units.length > 1 && (
            <Card>
              <GText g4 semi text={settings?.unit_display + " Info"} />
              {orderData?.order_units.map((unit, i) => {
                return (
                  <GContent
                    title={settings?.unit_display + " " + (i + 1)}
                    value={unit?.users + " Guests"}
                  />
                );
              })}
              <br />
            </Card>
          )}

          <Card>
            <GText g4 semi text={"Booking Info"} />
            {orderData?.starts && (
              <GContent title="Starts" value={orderData.starts_display} />
            )}
            {orderData?.ends && (
              <GContent title="Ends" value={orderData.ends_display} />
            )}

            {orderData?.order_units && orderData?.order_units.length == 1 && (
              <GContent
                title="Guests"
                value={orderData?.order_units[0].users}
              />
            )}

            {orderData?.name && (
              <GContent title="Name" value={orderData.name} />
            )}

            {orderData?.notes && (
              <GContent title="Instructions" value={orderData.notes} />
            )}
            <br />
          </Card>
        </div>
      </div>
      <div
        style={{
          width: "33%",
          display: "inline-block",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              Notes ({ordernotes.length})
              {/* <TooltipComponent
                title="Notes for Customer & Host!"
                position="bottom"
                id="order_notes"
              >
                <Info
                  fontSize="small"
                  style={{ display: "inline-block", color: "#F8310E" }}
                />
              </TooltipComponent> */}
            </CardHead>
          </div>
          {ordernotes && ordernotes.length >= 0 ? (
            <div
              style={{
                padding: "5px 15px",
                maxHeight: "340px",
                overflow: "overlay",
                marginBottom: "40px",
              }}
            >
              {ordernotes.map((p) => {
                return (
                  <NoteDiv key={p.id}>
                    <h5>{p.text}</h5>

                    <p>{p.created}</p>
                  </NoteDiv>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={noteVal}
              id="notes"
              placeholder="Add a note.."
              onChange={(e) => setNoteValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              disabled={noteBtnDisabled}
              onClick={() => {
                updateField("notes");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#F8310E",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send
                style={{
                  width: "18px",
                  height: "18px",
                  paddingTop: "2px",
                  fill: "#fff",
                }}
              />
            </GButton>
          </div>
        </Card>

        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
            marginBottom: "50px",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              Messages ({orderMessages.length})
              {/* <TooltipComponent
                title="Messages for Customer & Host!"
                position="bottom"
                id="order_messages"
              >
                <Info
                  fontSize="small"
                  style={{ display: "inline-block", color: "#F8310E" }}
                />
              </TooltipComponent> */}
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px 15px",
              maxHeight: "350px",
              overflow: "overlay",
              marginBottom: "40px",
            }}
          >
            {orderMessages.map((p) => {
              return (
                <div
                  key={p.id}
                  style={{ textAlign: p.user_type === 2 ? "right" : "left" }}
                >
                  <MessageBox
                    className={p.user_type === 2 ? "send" : "received"}
                  >
                    <h5>{p.text}</h5>

                    <p>
                      {p.user_type === 2 ? "" : p.user_title + ", "}
                      {p.created}
                    </p>
                  </MessageBox>
                </div>
              );
            })}
          </div>
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={messageVal}
              id="messages"
              placeholder="Add a message.."
              onChange={(e) => setMessageValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              disabled={msgBtnDisabled}
              onClick={() => {
                updateField("messages");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#F8310E",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send
                style={{
                  fill: "#fff",
                  width: "18px",
                  height: "18px",
                  paddingTop: "2px",
                }}
              />
            </GButton>
          </div>
        </Card>
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Booking cancel reason Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrvisible={setOcrvisible}
          linkedId={bookingId}
          onSubmit={getOrderDetail}
        />
      </Modal>
    </div>
  );
}
