import React, { useState, useEffect, useRef } from "react";
import { ApiService, getProductTitle } from "../services";
import styled from "styled-components";
import { ReactComponent as Close } from "../assets/images/close.svg";
import { ReactComponent as Search } from "../assets/images/search.svg";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import useDebounce from "../DataFactory/useDebounce";
import { ReactComponent as StoreIcon } from "../assets/images/sidebar/products.svg";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 13.5px;
  cursor: pointer;
  display: flex;
  &:hover {
    background: #F8310E;
    color: #fff;
  }
`;
const DIV = styled.div`
  background: transparent;
  border-radius: 4px;

  &:hover {
    background: #e6e7eb;
  }
  &.active {
    background: #f0d3c8;
    &:hover {
      background: #f0d3c8;
    }
  }
`;

const Input = styled.input`
  width: calc(100% - 45px);
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const ItemFilter = ({
  value,
  handleItemChange,
  handleBlur,
  hostId,
  handleFocus,
  style,
}) => {
  const [initialLoading, setInitialloading] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const debouncedSearchTerm = useDebounce(searchWord, 500);
  const wrapperHostRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperHostRef,
    false
  );
  const [selectedProducts, setSelectedProducts] = useState("");
  const [productsFilterList, setProducts] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    console.log(value);
    if (initialLoading == true) {
      if (!value) {
        setSelectedProducts("");
        setSelectedValues({});
      }
      setInitialloading(false);
    }

    getProducts();
  }, [value, hostId]);

  const getProducts = () => {
    ApiService({
      method: "GET",
      route:
        "products?per_page=100&search=" +
        searchWord +
        "&host_id=" +
        (hostId || ""),
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setProducts(response.data.data.data);
          response.data.data.data.map((values) => {
            if (values.id == value) {
              setSelectedValues(values);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProducts();
  }, [debouncedSearchTerm]);

  // useEffect(() => {
  //   if (initialLoading == false) {
  //     let selectedHostIds = [];
  //     console.log(selectedProducts);
  //     setSelectedValues([...selectedProducts]);
  //     if (selectedProducts.length > 0) {
  //       selectedProducts.map((s) => {
  //         selectedHostIds.push(s.id);
  //       });
  //     }
  //     handleItemChange(selectedHostIds);
  //   }
  // }, [selectedProducts]);

  return (
    <div
      id="hostFilter"
      ref={wrapperHostRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
          maxWidth: "300px",
          // verticalAlign: "middle",
        },
        style
      )}
    >
      <DIV
        style={{
          // background: "#fff",
          // border: "1px solid rgb(226, 226, 226)",
          borderRadius: "4px",
          minHeight: "30px",
          fontSize: "14px",
          color: "#555",
          boxSizing: "border-box",
          padding: "1px 5px 1px 4px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowFilterList(true);
          setSearchWord("");
        }}
        className={value ? "active" : ""}
      >
        {" "}
        <StoreIcon
          style={{
            fill: "#4D4D4D",
            margin: "0px 8px 0 2px",
            width: "14px",
            height: "14px",
          }}
        />
        {value && selectedValues?.id ? (
          <>
            <span
              style={{
                color: "#555",
                padding: "1px 3px 2px 0",
                fontWeight: "550",
                userSelect: "none",
              }}
            >
              {selectedValues?.custom_fields?.title}
            </span>

            <Close
              onClick={() => {
                setSelectedProducts("");
                setSelectedValues({});
                handleItemChange();
                setShowFilterList(false);
              }}
              style={{
                marginLeft: "auto",
                width: "11px",
                height: "11px",
                fill: "#777",
                cursor: "pointer",
                padding: "0 5px",
              }}
            />
          </>
        ) : (
          <span
            style={{
              color: "#555",
              padding: "1px 3px 2px 0",
              fontWeight: "550",
              userSelect: "none",
            }}
          >
            All {getProductTitle()}
          </span>
        )}
        {/* <KeyboardArrowDown
          style={{
            marginLeft: "auto",
            fontSize: "19px",
            color: "#555",
          }}
        /> */}
      </DIV>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            minWidth: "180px",
            padding: "0 2px 5px",
            border: "1px solid rgb(226, 226, 226)",
            marginTop: "5px",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                width: "18px",
                height: "18px",
                fill: "#ccc",
                margin: 0,
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              height: "220px",
              overflow: "auto",
            }}
          >
            {productsFilterList.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedValues(c);
                    setSelectedProducts(c.id);
                    handleItemChange(c.id);
                    setShowFilterList(false);
                  }}
                >
                  {c.title || c?.custom_fields?.title}

                  {/* {selectedValues
                    .filter((s) => s.id == c.id)
                    .map((c) => {
                      return (
                        <Check
                          style={{
                            float: "right",
                            marginLeft: "auto",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      );
                    })} */}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
